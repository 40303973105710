<template>
  <campaign-layout
    :showBack="computed_debug"
    :showMenu="computed_debug"
    :pageTitle="$t('pageTitle.Badges')"
  >
    <ion-grid>
      <ion-row>
        <ion-col
          v-for="item in badgesList"
          :key="item.id"
          size="4"
          size-md="4"
          size-lg="3"
        >
          <ion-card
            @click="presentAlert(item)"
            class="awardCard ion-no-padding no-background"
          >
            <ion-card-header :class="{ inactive: !item.vinto }">
              <img
                :src="item.url_image"
                class="avatar"
                @error="handleImgError"
              />
            </ion-card-header>
            <ion-card-content>
              <ion-row>
                <ion-col>
                  <common-progress-bar :value="item.perc_completamento / 100">
                  </common-progress-bar>
                </ion-col>
              </ion-row>
              <ion-row>
                <ion-col class="ion-text-center">
                  <h3>
                    {{ item.title["it-IT"].toUpperCase() }}
                  </h3>
                </ion-col>
              </ion-row>
            </ion-card-content>
          </ion-card>
          <div v-if="item.abilita_competenze" class="badge-competenza">
            <ion-icon :icon="star" class="badge-competenza-icon"></ion-icon>
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>
    <div v-if="showModal" :class="{ 'show-modal': showModal }" class="modal">
      <div class="modal-content">
        <div class="title-container">
          <h4>{{ $root.translate(modalData.title) }}</h4>
        </div>
        <div class="image">
          <ion-avatar>
            <img :src="modalData.url_image" @error="handleImgError" />
          </ion-avatar>
        </div>
        <div class="progressbar-container">
          <div style="text-align: center">
            {{ modalData.perc_completamento.toFixed(0) }}%
          </div>

          <common-progress-bar
            class="height-10"
            :value="modalData.perc_completamento / 100"
          ></common-progress-bar>
        </div>

        <div class="description-container">
          {{ $root.translate(modalData.description) }}
        </div>
        <ion-row class="ion-justify-content-center ion-margint-top">
          <ion-col size="auto">
            <ion-button @click="showModal = false"> Close </ion-button>
          </ion-col>
        </ion-row>
      </div>
    </div>
  </campaign-layout>
</template>

<script>
import {
  IonButton,
  IonProgressBar,
  IonGrid,
  IonRow,
  IonCol,
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonIcon,
  IonItem,
  IonLabel,
  // alertController,
} from "@ionic/vue";
import { ref } from "vue";
import { star } from "ionicons/icons";
import ApiService from "../../common/service.api";
import { translate } from "../../common/helper.auth";
import CampaignLayout from "../../components/CampaignLayout";
import { useRoute } from "vue-router";
import CommonProgressBar from "../../components/CommonProgressBar";

export default {
  name: "Badge",
  components: {
    IonButton,
    IonProgressBar,
    CampaignLayout,
    IonGrid,
    IonRow,
    IonCol,
    IonCard,
    IonCardHeader,
    IonCardContent,

    IonIcon,
    IonItem,
    IonLabel,
    CommonProgressBar,
  },
  data() {
    return {
      star,
      showModal: false,
      modalData: {
        title: {
          "it-IT": "",
          "en-EN": "",
        },
      },
    };
  },
  setup() {
    const route = useRoute();
    const { id } = route.params;

    const badgesList = ref([]);

    function apiGet() {
      ApiService.get(`CampaignManager/GetUserBadges/${id}`).then((res) => {
        res.data.forEach((element) => {
          console.log(res);
          res.perc_completamento = res.perc_completamento / 100;
          badgesList.value.push(element);
        });
      });
    }

    apiGet();

    return {
      id,
      badgesList,
      apiGet,
    };
  },
  methods: {
    handleImgError() {
      return "https://placehold.co/300x300?text=MartechRevolution";
    },
    presentAlert(item) {
      this.modalData = {};

      this.showModal = true;

      this.modalData = JSON.parse(JSON.stringify(item));

      console.log(this.modalData);
      // // const modal = await modalController.create({
      // //   component: ModalBadge,
      // //   cssClass: "my-custom-class",
      // //   componentProps: {
      // //     item: item,
      // //   },
      // // });
      // // return modal.present();
      // const alert = await alertController.create({
      //   cssClass: "my-custom-class",
      //   header: translate(item.title).toUpperCase(),
      //   subHeader: translate(item.description),
      //   message:
      //     '<div style="border-radius: 50%; overflow: hidden;" ><img  src="' +
      //     item.url_image +
      //     '"  style="border-radius: 2px" ></div>',
      //   buttons: ["Close"],
      // });
      // await alert.present();
      // const { role } = await alert.onDidDismiss();
      // console.log("onDidDismiss resolved with role", role);
    },
  },
  computed: {
    computed_debug() {
      if (this.$cookies.get("debug") == 1) {
        return false;
      } else {
        return true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.awardCard {
  display: flex;
  flex-direction: column;
}

h3 {
  font-size: 0.7rem;
}

ion-card-header {
  padding: 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border: 1px solid var(--text-color-light);

  img {
    padding: 5px;
  }

  //background: var(--ion-color-primary); /* fallback for old browsers */
  //background: -webkit-linear-gradient(
  //  to top,
  //  var(--ion-color-primary),
  //  var(--ion-color-secondary)
  //); /* Chrome 10-25, Safari 5.1-6 */
  //background: linear-gradient(
  //  to top,
  //  var(--ion-color-primary),
  //  var(--ion-color-secondary)
  //); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  border-radius: 200px;
}

ion-card-content {
  width: 100%;
  padding: 0;
  font-size: 0.3rem;
  font-weight: bold;
}

.awardCard img {
  align-self: center;
  justify-self: start;
  aspect-ratio: 1/1;
  width: 20rem;
  border-radius: 50%;
  border: 0.7px solid white;
  background: white;
}

.inactive {
  filter: grayscale(100);
}

.modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  visibility: hidden;
  transform: scale(1.1);
  transition: visibility 0s linear 0.25s, opacity 0.25s 0s, transform 0.25s;
  z-index: 10;
}

.modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 1rem 1.5rem;
  width: 80vw;
  border-radius: 0.5rem;
}

.close-button {
  float: right;
  width: 1.5rem;
  line-height: 1.5rem;
  text-align: center;
  cursor: pointer;
  border-radius: 0.25rem;
  background-color: lightgray;
}

.close-button:hover {
  background-color: darkgray;
}

.show-modal {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
  transition: visibility 0s linear 0s, opacity 0.25s 0s, transform 0.25s;
}

.title-container {
  margin-bottom: 3rem;
}

.progressbar-container {
  padding: 3rem 0;
  width: 30vw;
  margin: auto;

  ion-progress-bar {
    border-radius: 20px;
  }
}

.description-container {
  margin-bottom: 3rem;
}

.image {
  display: grid;
  place-content: center;

  ion-avatar {
    width: 40vw;
    height: 40vw;
  }
}

.badge-competenza {
  position: absolute;
  top: 90px;
  right: 15px;
  width: 35px;
  height: 35px;
  background-color: goldenrod;
  z-index: 10;
  border-radius: 50%;

  display: grid;
  place-content: center;
}

.badge-competenza-icon {
  font-size: 1rem;
  color: white;
  z-index: 10;
}
</style>
